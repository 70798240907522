import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import HomeView from './views/HomeView';
import './index.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import TemplatesPreview from './certificate-templates/TemplatesPreview';
import NoMatch from './views/NoMatch';

const router = createBrowserRouter([
	{
		path: '/',
		element: <HomeView />,
	},
	{
		path: '/templates/:id',
		element: <TemplatesPreview />,
	},
	{
		path: '*',
		element: <NoMatch />,
	},
]);

createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<Provider store={store}>
			<div className="">
				<RouterProvider router={router} />
			</div>
		</Provider>
	</React.StrictMode>,
);
