import { useEffect, useState } from 'react';
import api from '../services/api';
export const useMessaging = () => {
	const dashboardUrl = `${process.env.REACT_APP_DASHBOARD_URL}`;
	const [isLoading, setIsLoading] = useState(true);

	const handleDarkMode = (darkMode: boolean) => {
		if (darkMode) {
			document.body.classList.add('dark');
		} else {
			document.body.classList.remove('dark');
		}
	};
	useEffect(() => {
		window.parent.postMessage({ call: 'initIframe' }, dashboardUrl);
		const handleMessage = (event: MessageEvent) => {
			const origin = event.origin;
			console.log(origin, 'origin');

			if (origin !== dashboardUrl) {
				setIsLoading(false);
				return;
			}
			if (typeof event.data == 'object') {
				console.log(event.data, 'event data');
				if (event.data.call == 'darkMode') {
					handleDarkMode(event.data.darkMode);
				}
				if (event.data.call == 'sendInitialData') {
					const { darkMode, token, _token } = event.data;
					console.log(token.key, _token, 'token useMessaging');
					if (token.key) {
						localStorage.setItem('token', token.key);
						localStorage.setItem('id', token.id);
						localStorage.setItem('_token', _token);
						api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
					} else {
						localStorage.removeItem('token');
						delete api.defaults.headers.common['Authorization'];
					}
					handleDarkMode(darkMode);
					setIsLoading(false);
				}
			}
		};
		window.addEventListener('message', handleMessage, false);
		setIsLoading(false);
		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, []);
	return { isLoading };
};
