import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { Product, Template, product_ids } from '../types';

export const useActiveTemplate = () => {
	const activeTemplate: Template = useSelector((state: RootState) => state.templates.activeTemplate) as Template;
	const status = useSelector((state: RootState) => state.templates.status);
	return [status, activeTemplate] as const;
};

export const useProducts = () => {
	const selectedProducts: product_ids = useSelector(
		(state: RootState) => state.templates.activeTemplate.products_ids,
	);
	const products: Product[] = useSelector((state: RootState) => state.templates.products);
	const status = useSelector((state: RootState) => state.templates.status);
	return [status, selectedProducts, products] as const;
};
