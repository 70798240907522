import * as React from 'react';

function PagesHeader() {
	const [isOpen, setIsOpen] = React.useState(true);

	return (
		<>
			{isOpen && (
				<div className="bg-gray-50 flex h-full justify-between items-start gap-1 sm:gap-4 p-2 sm:p-5 text-dark-200 dark:bg-dark-800 dark:text-dark-100 dark:border-gray-400">
					<div className="flex flex-col flex-grow sm:flex sm:flex-row gap-4">
						<a
							href="#"
							className="h-16 w-16 sm:h-32 sm:w-32 text-base font-normal flex flex-shrink-0 gap-2 items-center justify-center text-primary-l order-1 sm:order-none"
						>
							<img src="./images/video.png" className="h-full w-full object-cover " alt="" />
						</a>
						<div className="flex flex-col">
							<h3 className="panel__title text-primary font-medium text-xl dark:text-secondary-50">
								قوالب الشهادات
							</h3>
							<p className="text-lg text-dark-300 font-light dark:text-dark-100">
								هي عبارة عن نماذج جاهزة تسهل إختيار نوع الشهادة التي ترسل لعملائك حسب نشاط متجرك.
							</p>
						</div>
					</div>
					<div className="flex flex-col min-h-full items-end justify-between  self-stretch">
						<div>
							<button onClick={() => setIsOpen(false)} className="sicon-cancel text-red-500"></button>
						</div>
						<div>
							<a
								href="https://help.salla.sa"
								className="text-sm font-normal flex gap-2 items-center w-28 text-primary-l dark:text-secondary-50"
							>
								مركز المساعدة
								<span className="sicon-share"></span>
							</a>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default PagesHeader;
