import Swal from 'sweetalert2';
import { SweetAlertOptions } from '../types';
export default function useSwal() {
	const $confirm = (options: SweetAlertOptions) => {
		return Swal.fire({
			icon: 'warning',
			title: 'هل أنت متأكد؟',
			showCancelButton: true,
			confirmButtonText: 'نعم',
			cancelButtonText: 'لا',
			customClass: {
				confirmButton: 'bg-red-200 text-red-500',
				cancelButton: 'bg-gray-300 text-gray-500',
			},
			...options,
		});
	};

	const $error = ({ title = 'خطأ', text = 'حدث خطأ ما' }) => {
		return Swal.fire({
			icon: 'error',
			title: title,
			text: text,
			confirmButtonText: 'موافق',
			confirmButtonColor: '#76E8CD',
			timer: 1000,
		});
	};

	const $success = ({ title = 'تم', text = 'تمت العملية بنجاح' }) => {
		return Swal.fire({
			icon: 'success',
			title: title,
			text: text,
			confirmButtonText: 'موافق',
			confirmButtonColor: '#76E8CD',
			timer: 1000,
		});
	};
	const fire = (options: SweetAlertOptions = {}) => {
		return Swal.fire(options);
	};
	return {
		$error,
		$confirm,
		$success,
		fire,
	};
}
