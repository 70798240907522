import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';
import { TEMPLATES, STORE_TEMPLATES, PRODUCTS_URL } from '../../endpoints';
import { Template } from '../../types';

export const getSystemTemplatesAsync = createAsyncThunk('get suggested templates', async () => {
	const { data } = await api.get(TEMPLATES);
	return data;
});

export const getStoreTemplatesAsync = createAsyncThunk(
	'get store templates',
	async ({ limit }: { limit?: number | '' }) => {
		const { data } = await api.get(STORE_TEMPLATES + `?limit=${limit}`);
		return data;
	},
);

export const addTemplateAsync = createAsyncThunk('add template', async ({ template }: { template: Template }) => {
	const { data } = await api.post(STORE_TEMPLATES, {
		products_ids: template.products_ids,
		template_id: template.id,
	});
	return data;
});

export const editTemplateAsync = createAsyncThunk('edit template', async ({ template }: { template: Template }) => {
	const { data } = await api.put(`${STORE_TEMPLATES}/${template.id}`, {
		products_ids: template.products_ids,
	});
	return data;
});

export const activateTemplateAsync = createAsyncThunk(
	'activate template',
	async ({ template }: { template: Template }) => {
		const { data } = await api.put(`${STORE_TEMPLATES}/${template.id}/activation`);
		return data;
	},
);

export const deleteTemplateAsync = createAsyncThunk('delete template', async ({ template }: { template: Template }) => {
	const { data } = await api.delete(`${STORE_TEMPLATES}/${template.id}`);
	return data;
});

export const getProductsAsync = createAsyncThunk('/api/products', async () => {
	const { data } = await api.get(`${PRODUCTS_URL}`);
	return data;
});
