import * as React from 'react';
import UISwitch from '../UISwitch';
import UIDropdown from '../Dropdown/UIDropdown';
import { Template } from '../../../types';
import { useAppDispatch } from '../../../app/store';
import {
	activateTemplateAsync,
	deleteTemplateAsync,
	getProductsAsync,
	getSystemTemplatesAsync,
} from '../../../features/templates/TemplateReducers';
import './Card.scss';
import useSwal from '../../../hooks/useSwal';
type Props = {
	template: Template;
	handleClick: (template: Template) => void;
	overlay?: boolean;
};

function Card({ template, handleClick, overlay }: Props) {
	const { name, main_text, thumbnail, type, is_active } = template;
	const [checked, setChecked] = React.useState<boolean>(is_active);
	const dispatch = useAppDispatch();
	const activateTemplate = (status: boolean | ((prevState: boolean | number) => boolean)) => {
		setChecked(status);
		dispatch(activateTemplateAsync({ template }));
	};
	const { $confirm } = useSwal();
	const deleteTemplate = () => {
		$confirm({
			title: 'تأكيد حذف القالب',
			text: 'في حال تأكيد الحذف لن تستطيع استرجاع إعدادات التخصيص المدرجة على القالب',
			cancelButtonText: 'إلغاء',
			confirmButtonText: 'حذف',
		}).then(async (result) => {
			if (result.isConfirmed) {
				dispatch(deleteTemplateAsync({ template })).then(() => {
					dispatch(getProductsAsync()).then(() => {
						dispatch(getSystemTemplatesAsync());
					});
				});
			}
		});
	};

	const dropdownOptions = [
		{
			text: 'تعديل',
			icon: 'sicon-edit',
			onClick: () => handleClick(template),
			color: 'dark:text-dark-100',
			hoverColor: 'text-dark-500',
		},
		{
			text: 'حذف',
			icon: 'sicon-trash',
			onClick: () => deleteTemplate(),
			color: 'text-red-500',
			hoverColor: 'text-red-600',
		},
	];

	return (
		<>
			<div
				className="card"
				onClick={() => {
					template.type === 'suggested' && handleClick(template);
				}}
			>
				{overlay && (
					<div className="card__overlay">
						<button
							className="bg-secondary-50 text-primary-l font-medium py-2 px-4 rounded-2xl flex gap-2 justify-center items-center"
							onClick={() => {
								handleClick(template);
							}}
						>
							<span className="sicon-eye"></span>
							عرض كل النماذج
						</button>
					</div>
				)}
				<img src={thumbnail} alt="template" className="card__image__img h-[333px] w-full" />
				<div className="card__body ">
					{type === 'suggested' && !overlay && (
						<div className="p-4">
							<h4 className="card__title ">{name}</h4>
							<p className="card__description">{main_text}</p>
						</div>
					)}

					{type === 'store' && !overlay && (
						<>
							<div className="card__actions">
								<div className="w-8 h-8 flex items-center justify-center">
									<UIDropdown options={dropdownOptions} />
								</div>
								<UISwitch onChange={activateTemplate} checked={checked} />
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default Card;
