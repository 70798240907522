import React, { useEffect } from 'react';
import Panel from '../components/UI/Panel/Panel';
import CardsWrapper from '../components/UI/Card/CardsWrapper';
import { Provider, useSelector } from 'react-redux';
import { RootState, store } from '../app/store';
import PagesHeader from '../components/PagesHeader';
import { useMessaging } from '../hooks/useMessaging';
import UILoader from '../components/UI/UILoader';
import { ErrorBoundary } from 'react-error-boundary';

function fallbackRender({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) {
	return (
		<div className="w-full h-full flex items-center justify-center space-y-4 flex-col pt-12 px-8">
			<p className="text-3xl text-gray-400">حدث خطأ ما</p>
			<pre className="text-lg text-gray-500 italic ">{error.message}</pre>
			<button
				className="bg-secondary-50 text-primary-l font-medium py-2 px-4 rounded-md flex gap-2 justify-center items-center"
				onClick={resetErrorBoundary}
			>
				الذهاب للصفحة الرئيسية
			</button>
		</div>
	);
}

import {
	getProductsAsync,
	getStoreTemplatesAsync,
	getSystemTemplatesAsync,
} from '../features/templates/TemplateReducers';

function HomeView() {
	const { suggestedTemplates, storeTemplates } = useSelector((state: RootState) => state.templates);
	const { isLoading } = useMessaging();
	useEffect(() => {
		if (!isLoading) {
			store.dispatch(getSystemTemplatesAsync());
			store.dispatch(getStoreTemplatesAsync({ limit: 8 }));
			store.dispatch(getProductsAsync());
		}
	}, [isLoading]);

	if (isLoading) return <UILoader />;
	return (
		<>
			<Provider store={store}>
				<div className="py-8 px-8 mb-8">
					<PagesHeader />
					<ErrorBoundary fallbackRender={fallbackRender}>
						<Panel title="قوالب الشهاادت المقترحة لك">
							<CardsWrapper templates={suggestedTemplates} />
						</Panel>

						<Panel title="النماذج الخاصة بك">
							<CardsWrapper templates={storeTemplates} />
						</Panel>
					</ErrorBoundary>
				</div>
			</Provider>
		</>
	);
}

export default HomeView;
