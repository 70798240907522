import { configureStore } from '@reduxjs/toolkit';
import templateReducer from '../features/templates/TemplatesSlice';
import { useDispatch } from 'react-redux';
import { Middleware } from 'redux';
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
import logger from 'redux-logger';

const middlewares: Middleware[] = process.env.NODE_ENV === 'development' ? [logger] : [];

export const store = configureStore({
	reducer: {
		templates: templateReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
});
