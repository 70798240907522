import * as React from 'react';
import './Panel.scss';

type Props = {
	title?: string;
	buttonText?: string;
	children?: React.ReactNode;
};

const Panel = ({ title, buttonText, children }: Props) => {
	return (
		<div className="panel">
			<div className="panel__header">
				<h3 className="panel__title">{title}</h3>
				{buttonText && <button className="panel__button">{buttonText}</button>}
			</div>
			<div className="panel__body">{children}</div>
		</div>
	);
};

export default Panel;
